:global {
.student-list-table {
    .table-wrap {
        .MuiTableContainer-root {
        .MuiTableBody-root {
            .MuiTableCell-body{
                .name-text{
                    cursor: pointer;
                    color: var(--Orange-100);
                    font-weight: 600;
                    text-decoration: underline;
                    &:hover {
                        color: var(--Orange-150);
                    }
                }
            }
        }
        }
    }
  }
}
