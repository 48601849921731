@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap");
@import "./styles/variables.scss";
@import "./styles/global.scss";

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish";
}

::-webkit-scrollbar-track {
  background-color: var(--Grey-30);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: var(--Grey-30);
}

::-webkit-scrollbar-thumb {
  background-color: var(--Blue-100);
}
