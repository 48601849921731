:global {
  .input-wrap {
    width: 100%;
    border-radius: 8px;
    background: var(--Grey-10);
    .MuiOutlinedInput-root {
      height: 46px;
      padding: 5px 16px;
    }
    .MuiInputBase-input {
      color: var(--Black);
      font-size: 16px;
      font-weight: 400;
      &::placeholder {
        color: var(--Grey-50);
      }
    }
    .MuiInputAdornment-root {
      margin-right: 10px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  }
}
