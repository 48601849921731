:root {
  --Black: #000000;

  --Blue-00:#F9FAFB;
  --Blue-10: #ccdde8;
  --Blue-20:#AAC6D9;
  
  --Blue-100: #00558c;

  --White: #ffffff;

  --Grey-00: #f3f3f3;
  --Grey-10: #f7f7f7;
  --Grey-20: #ececec;
  --Grey-30: #dcdcdc;
  --Grey-40: #d9d9d9;
  --Grey-50: #b5b5b5;
  --Grey-60: #919191;
  --Grey-70: #979797;
  --Grey-80: #8d8d8d;
  --Grey-100: #121212;

  --Orange-10: #fde5e1;
  --Orange-100: #e04e39;
  --Orange-150:#bb4331;

  --Green-10: #def7ec;
  --Green-100: #04543f;
  --Green-50: #0c9f6e;

  --Yellow-100: #f2b518;

  --Red-100: #c10a0a;
}
