:global {
  .table-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid var(--Grey-30);
    border-radius: 16px;
    .MuiTableContainer-root {
      max-height: 100%;
      .MuiTableRow-head {
        .MuiTableCell-root {
          &:first-child {
            padding: 24px 47px;
          }
          &:last-child {
            padding: 24px 47px;
          }
        }
        .MuiTableCell-head {
          background-color: var(--Grey-00);
          color: var(--Black);
          font-size: 16px;
          font-weight: 700;
          line-height: 17px;
          border-color: var(--Grey-30);
        }
      }
      .MuiTableBody-root {
        .MuiTableRow-root {
        &:nth-of-type(odd) {
          background-color: var(--Blue-00);
        }
      }
        .MuiTableCell-root {
          .program-data-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            .initialLit-data {
              height: 24px;
              min-width: 24px;
              padding: 0 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: var(--Blue-10);
              color: var(--Blue-100);
              font-weight: 700;
              border-radius: 24px;
            }
          }
        }
        .MuiTableCell-body {
          color: var(--Black);
          font-size: 16px;
          font-weight: 400;
          color: #000;
          font-family: Mulish;
          font-size: 16px;
          font-weight: 400;
          white-space: pre;
          border-color: var(--Grey-30);
          &:first-child {
            padding: 13px 47px;
          }
          &:last-child {
            padding: 13px 47px;
          }
        }
        .MuiTableRow-hover {
          &:hover {
            background-color: var(--Blue-00);
          }
        }
      }
    }
  }
}
