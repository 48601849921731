:global {
  .MuiButtonBase-root {
    &.btn-wrap {
      white-space: pre;
      min-width: unset;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      text-transform: capitalize;
      &.MuiButton-contained {
        background-color: var(--Orange-100);
        color: var(--White);
        &:hover {
          background-color: var(--Orange-150);
        }
      }
      &.MuiButton-outlined {
        border-color: var(--Orange-100);
        color:var(--Orange-100);
        &:hover {
            background-color: var(--Orange-10);
        }
      }
      &.MuiButton-sizeLarge {
        padding: 12px 16px;
      }
      &.MuiButton-sizeMedium {
        padding: 8px 16px;
      }
    }

    // &.btn-red {
    //   background-color: red;
    // }
  }
}
