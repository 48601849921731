:global {
  .menu-popover-paper {
    margin: 10px 0 0 0;
    &.MuiPaper-root {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 8px;
      .MuiMenu-list {
        padding: 0;
        overflow: hidden;
      }
      .MuiMenuItem-root {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 12px;
      }
      .Mui-focusVisible{ 
        background-color: var(--Grey-10); 
          color: var(--Black);
      }
      .MuiButtonBase-root {
        &:hover {
          background-color: var(--Blue-100); 
          color: var(--White);
         
        }
      }
    }
  }
}
